import { waitForElement } from '@/lib/wait_for_element';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    path: String,
  };

  declare pathValue: string;
  declare hasPathValue: boolean;

  async connect(): Promise<void> {
    if (!this.hasPathValue) return;
    if (!window.ui) return;

    await waitForElement('.modal-blind');

    window.ui.modal.open({
      url: this.pathValue,
      id: 'regionModal',
      close: false,
    });
  }

  disconnect(): void {
    if (!window.ui) return;
    window.ui.modal.close({ id: 'regionModal' });
  }
}
